import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import env from "Consts/env";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const mobile = require("is-mobile");

const MetaHead = dynamic(
	() => import("Containers/meta_head"),
);

const Layout = dynamic(
	() => import("Containers/layout"),
);

const IndexContainer = dynamic(
	() => import("Containers/index_container/index_container"),
	{
		ssr: false,
	},
);

// const ComingSoonContainer = dynamic(
// 	() => import("Containers/coming_soon/coming_soon_container"),
// 	{
// 		ssr: false,
// 	},
// );

const Index = () => {

	const [isMobile, setIsMobile] = useState(true);
	const [tickets, setTickets] = useState([]);

	const getDataCategory = async () => {

		try {

			const response = await fetch(
				`${process.env.NEXT_PUBLIC_ECM_DOMAIN}/api/fds-category/2?type=is_virtual`,
				{
					headers: { ...env.HEADERS, "api-key": process.env.NEXT_PUBLIC_FDS_API_KEY },
					method: "get",
				},
			);
			const jsonData = await response.json();
			const dataFiltered = jsonData?.data.filter((word) => word.includes("surabaya"));
			const category = dataFiltered[0];

			if (category) {

				const resposeTicket = await fetch(
					`${process.env.NEXT_PUBLIC_ECM_DOMAIN}/api/fds-product?page=1&limit=12&
					parent_category=${category}&category=&sort=&brand=&min_price=&max_price=&
					sale=&query=&placement=2`,
					{
						headers: { ...env.HEADERS, "api-key": process.env.NEXT_PUBLIC_FDS_API_KEY },
						method: "get",
					},
				);
				const dataTicket = await resposeTicket.json();

				const listOfTicket = dataTicket?.data?.products;

				setTickets(listOfTicket);

			}

		} catch (error) {

			console.log("Ticket Error", error);

		}

	};

	useEffect(() => {

		setIsMobile(mobile());

		getDataCategory();

	}, []);

	return (
		<>

			<Layout
				home
				title="The Biggest Beauty Event In The World - Surabaya X Beauty"
				ismobile={isMobile}
			>
				<MetaHead />

				<main id="main-content">
					<section className="container">

						<IndexContainer tickets={tickets} isMobile={isMobile} />
						{/* <ComingSoonContainer isMobile={isMobile} /> */}

					</section>
				</main>
			</Layout>

		</>
	);

};

export default Index;
